import { Container, Row, Col, Card, Modal, Spinner, Accordion } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import TarsService from '../services/TarsService';
import DruoService from '../services/DruoService';
import dayjs from 'dayjs';
import MaskedInput from "react-text-mask";
import { createNumberMask } from "text-mask-addons";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import QRCode from "react-qr-code";

const defaultMaskOptions = {
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2, // how many digits allowed after the decimal
    //   integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
}

const currencyMask = createNumberMask(defaultMaskOptions)

const WompiButton = ({ data }) => {

    const formRef = useRef(null)

    useEffect(() => {
        const script = document.createElement('script');

        if (script.readyState) {  // only required for IE <9
            script.onreadystatechange = function () {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;

                    const iframes = document.getElementsByClassName("waybox-iframe")
                    for (let element of iframes) {
                        element.setAttribute("scrolling", "yes");
                    };

                }
            };
        } else {  //Others
            script.onload = function () {
                const iframes = document.getElementsByClassName("waybox-iframe")
                for (let element of iframes) {
                    element.setAttribute("scrolling", "yes");
                };
            };
        }

        script.src = "https://checkout.wompi.co/widget.js";
        script.setAttribute("data-render", "button")
        script.setAttribute("data-public-key", process.env.REACT_APP_WOMPI_PUBLIC_KEY)
        script.setAttribute("data-currency", "COP")
        script.setAttribute("data-amount-in-cents", data.AmountInCents)
        script.setAttribute("data-reference", data.PaymentReference)
        script.setAttribute("data-signature:integrity", data.EncryptedString)
        script.setAttribute("data-redirect-url", process.env.REACT_APP_URL_PAYMENT_REDIRECT)

        formRef.current.appendChild(script);
    }, []);

    return <form ref={formRef} >
    </form>
}

const Payment = ({ goToChan }) => {
    const INTENTOS = 11;
    const TIEMPO_ESPERA = 60;

    const { GetReferenceInfo, CustomPaymentAmount, GetBankList, GetPSEUrl, CreateNequiPayment, GetNequiPayment, CancelNequiPayment,
        CreateDruoAccount, GetPendingDruoAccount
    } = TarsService();

    const { GetAccountInfo
    } = DruoService();

    const ReferenciaRef = useRef(null)
    const navigate = useNavigate();
    const [referenceInfo, setReferenceInfo] = useState(null);
    const [reference, setReference] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [referenceError, setReferenceError] = useState(false);
    const [cont, setCont] = useState(0);
    const [contTimeout, setContTimeout] = useState(TIEMPO_ESPERA);
    const [contMessage, setContMessage] = useState(false);
    const [show, setShow] = useState(false);

    const [selectedReference, setSelectedReference] = useState({});
    const [selectedMinimumPayment, setSelectedMinimumPayment] = useState({});
    const [paymentType, setPaymentType] = useState(null);
    const [showBancolombiaModal, setShowBancolombiaModal] = useState(false);
    const [showCBBancolombiaModal, setShowCBBancolombiaModal] = useState(false);
    const [showCMBancolombiaModal, setShowCMBancolombiaModal] = useState(false);
    const [showSVABancolombiaModal, setShowSVABancolombiaModal] = useState(false);
    const [showGUBancolombiaModal, setShowGUBancolombiaModal] = useState(false);
    const [showEfectyModal, setShowEfectyModal] = useState(false);
    const [showPSEModal, setShowPSEModal] = useState(false);
    const [showNequiModal, setShowNequiModal] = useState(false);
    const [showDRUOModal, setShowDRUOModal] = useState(false);

    const [amountError, setAmountError] = useState(false);
    const [showAdvancePaymentModal, setShowAdvancePaymentModal] = useState(false);
    const [showOverPaymentModal, setShowOverPaymentModalModal] = useState(false);
    const [showPendingPaymentModal, setShowPendingPaymentModal] = useState(false);

    const [showPlanModal, setShowPlanModal] = useState(false);
    const [opOption, setOpOption] = useState(0);
    const [apOption, setApOption] = useState(0);
    let moneyLocale = Intl.NumberFormat('es-CO');
    const MySwal = withReactContent(Swal);
    const [selectValue, setSelectValue] = useState("");
    const [bank, setBank] = useState("0");
    const [bankList, setBankList] = useState([]);

    const [FPayment, setFPayment] = useState(0);

    const [intervalNequi, setIntervalNequi] = useState(0);
    const [QRvalue, setQRvalue] = useState(null);
    const [showAnotherNumberForm, setShowAnotherNumberForm] = useState(false);
    const [anotherNumber, setAnotherNumber] = useState("");
    const [anotherNumberError, setAnotherNumberError] = useState("");
    const [pendingUPPayment, setPendingUPPayment] = useState(false);
    const [transactionIdNequi, setTransactionIdNequi] = useState();
    const [DruoPolicy, setDruoPolicy] = useState(false);
    const [DruoBank, setDruoBank] = useState("0");
    const [AccountTypeDRUO, setAccountTypeDRUO] = useState("");
    const [accountNumberDRUO, setAccountNumberDRUO] = useState("");
    const [accountNumberDRUOErrors, setAccountNumberDRUOErrors] = useState(false);
    const [druoData, setDruoData] = useState(null);

    const [DruoBanks, setDruoBanks] = useState([
        {
            "InstitutionName": "AV Villas",
            "UUID": "ins_2ef80a64-f1de-423c-a7a0-841e5cc8fbfe",
            "Country": "COL",
            "Payments Processing time": "NEXT-DAY",
            "Payouts Processing time": "NEXT-DAY",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Bancamía",
            "UUID": "ins_147fbec7-f608-4061-8c59-b6b309c9a18d",
            "Country": "COL",
            "Payments Processing time": "Not Available",
            "Payouts Processing time": "Not Available",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Banco Agrario",
            "UUID": "ins_ab67ed86-c58a-406e-b49c-b424a83c9247",
            "Country": "COL",
            "Payments Processing time": "NEXT-DAY",
            "Payouts Processing time": "NEXT-DAY",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Banco Caja Social",
            "UUID": "ins_23b043d2-56a5-4778-a8b1-c2f5e8af88a1",
            "Country": "COL",
            "Payments Processing time": "NEXT-DAY",
            "Payouts Processing time": "NEXT-DAY",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Banco Compartir",
            "UUID": "ins_5c6705ac-104e-429d-b085-1030fb74b81c",
            "Country": "COL",
            "Payments Processing time": "NEXT-DAY",
            "Payouts Processing time": "NEXT-DAY",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Banco Cooperativo Coopcentral",
            "UUID": "ins_95d15e2f-f9a1-44c6-8b0e-2d9ac833cd8e",
            "Country": "COL",
            "Payments Processing time": "NEXT-DAY",
            "Payouts Processing time": "NEXT-DAY",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Banco Credifinanciera",
            "UUID": "ins_25b2ec0d-953b-430f-b2c0-4a026171a0b5",
            "Country": "COL",
            "Payments Processing time": "Not Available",
            "Payouts Processing time": "Not Available",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Banco de Bogotá",
            "UUID": "ins_12b3d055-9601-4f5c-a5cd-f9feeb5119da",
            "Country": "COL",
            "Payments Processing time": "SAME-DAY*",
            "Payouts Processing time": "SAME-DAY*",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Banco de Occidente",
            "UUID": "ins_fa51b9bb-c9c8-4581-8c1d-95eed23fb07c",
            "Country": "COL",
            "Payments Processing time": "NEXT-DAY",
            "Payouts Processing time": "NEXT-DAY",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Banco Falabella",
            "UUID": "ins_0425f690-b10e-45a8-b9b8-a05bb31847b6",
            "Country": "COL",
            "Payments Processing time": "NEXT-DAY",
            "Payouts Processing time": "NEXT-DAY",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Banco Finandina",
            "UUID": "ins_f323fc72-b240-43ab-82bb-5a0b1b46587b",
            "Country": "COL",
            "Payments Processing time": "Not Available",
            "Payouts Processing time": "Not Available",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Banco Pichincha",
            "UUID": "ins_9aec632a-2d30-4553-a575-0aaa9e863729",
            "Country": "COL",
            "Payments Processing time": "Not Available",
            "Payouts Processing time": "Not Available",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Banco Popular",
            "UUID": "ins_5d5b7ce9-caa8-4cc8-be99-3fcff9d313c4",
            "Country": "COL",
            "Payments Processing time": "NEXT-DAY",
            "Payouts Processing time": "NEXT-DAY",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Banco Serfinanza",
            "UUID": "ins_3cbe1b04-f16a-4a63-b66d-a8f6d4ee14af",
            "Country": "COL",
            "Payments Processing time": "NEXT-DAY",
            "Payouts Processing time": "NEXT-DAY",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Bancolombia",
            "UUID": "ins_e42460a8-c783-4bc3-8dcd-2e6fcab78b77",
            "Country": "COL",
            "Payments Processing time": "INSTANT*, SAME-DAY**",
            "Payouts Processing time": "SAME-DAY*",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Bancoomeva",
            "UUID": "ins_f250caf1-de96-4a60-a9ff-863273f08205",
            "Country": "COL",
            "Payments Processing time": "NEXT-DAY",
            "Payouts Processing time": "NEXT-DAY",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "BBVA",
            "UUID": "ins_4fd895cd-c696-4b1a-b406-d4ae89408055",
            "Country": "COL",
            "Payments Processing time": "SAME-DAY*",
            "Payouts Processing time": "SAME-DAY*",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Citibank",
            "UUID": "ins_3f8856a5-e053-44ab-bbe7-96409615a78f",
            "Country": "COL",
            "Payments Processing time": "NEXT-DAY",
            "Payouts Processing time": "NEXT-DAY",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Davivienda",
            "UUID": "ins_9082d56c-4e1d-4967-a828-b7001755e88e",
            "Country": "COL",
            "Payments Processing time": "SAME-DAY*",
            "Payouts Processing time": "SAME-DAY*",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "GNB Sudameris",
            "UUID": "ins_3c58c6c6-09f7-4486-9829-f3ac97895dac",
            "Country": "COL",
            "Payments Processing time": "NEXT-DAY",
            "Payouts Processing time": "NEXT-DAY",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Itaú",
            "UUID": "ins_4c07fe2a-aa75-425a-9814-f8e5d182fc72",
            "Country": "COL",
            "Payments Processing time": "NEXT-DAY",
            "Payouts Processing time": "NEXT-DAY",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Banco W",
            "UUID": "ins_deb849ff-ba48-4801-825f-0e054bee2566",
            "Country": "COL",
            "Payments Processing time": "Not Available",
            "Payouts Processing time": "Not Available",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "IRIS",
            "UUID": "ins_97666971-030f-469a-8f9a-4b804f91e04d",
            "Country": "COL",
            "Payments Processing time": "Not Available",
            "Payouts Processing time": "Not Available",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Nequi",
            "UUID": "ins_19ee97b1-96ea-4f13-8720-869a53e22a37",
            "Country": "COL",
            "Payments Processing time": "INSTANT",
            "Payouts Processing time": "Not Available",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Santander",
            "UUID": "ins_e1bbe286-1cd8-4a25-82f2-a7a570c2cb6f",
            "Country": "COL",
            "Payments Processing time": "Not Available",
            "Payouts Processing time": "Not Available",
            "Checkout Link Processing time": "INSTANT"
        },
        {
            "InstitutionName": "Scotiabank Colpatria",
            "UUID": "ins_1a2d8c6f-f81c-4967-9121-26877baf1396",
            "Country": "COL",
            "Payments Processing time": "NEXT-DAY",
            "Payouts Processing time": "NEXT-DAY",
            "Checkout Link Processing time": "INSTANT"
        }
    ]);


    useEffect(() => {
        document.title = "Pagos";

        window.scrollTo(0, 0);

        ReferenciaRef.current.focus();

        const queryParameters = new URLSearchParams(window.location.search)
        const tempReference = queryParameters.get("reference")

        if (tempReference) {
            setReference(tempReference);
            searchReference(tempReference);
        }

    }, []);

    useEffect(() => {
        ReferenciaRef.current.focus();
    }, [referenceInfo]);

    useEffect(() => () => {
        clearInterval(intervalNequi);

    }, []);


    const goToContact = () => {
        navigate("/contact");
        window.scrollTo(0, 0)
    }

    const searchReference = (internalReference) => {

        GetBankList().then(res => {
            var data = JSON.parse(res.data?.data ?? null);
            setBankList(data ?? [])
        });


        setErrorMessage(null);
        setReferenceError(false);

        if (!internalReference) {
            setReferenceError(true);
            return;
        }

        let tempCont = cont + 1
        setCont(tempCont);

        if (tempCont >= INTENTOS) {
            setReferenceError(false);
            setReference("");
            setErrorMessage(null);
            setContMessage(true);

            let intervalId;
            intervalId = setInterval(() => {


                setContTimeout(t => {

                    const modifiedValue = t - 1;

                    if (modifiedValue <= 0) {
                        clearInterval(intervalId);
                        setContMessage(false);
                        setCont(0);
                        return TIEMPO_ESPERA;
                    }
                    return modifiedValue;

                });


            }, 1000);

            return;


        }

        setLoading(true);
        GetReferenceInfo(internalReference).then(res => {
            setLoading(false);
            if (res.status === 200) {

                var data = JSON.parse(res.data.data);
                setReferenceInfo(data)
            }
            else {



                setReference("");
                setErrorMessage(res.data.message);
                setReferenceError(true);
                ReferenciaRef.current.focus();
            }

        });



    }


    const handleOpenModal = (r) => {

        setLoading(true);

        GetAccountInfo(r.IdCustomer).then(res => {
            setLoading(false)
            setShow(true)
            setSelectedReference(r)

            let tempMIP = r.Payments.find(p => p.IdConcept === "MIP");
            setSelectedMinimumPayment(tempMIP)


            let data = res.data.data
            setDruoData(data)

            if (data && data.pendingPayment) {
                Swal.fire({
                    title: "PAGO PENDIENTE",
                    html: `Se realizó un débito automático el <b>${data.paymentDateCreatedLabel}</b> por valor de <b>${data.amount}</b> el cual está siendo procesado por tu banco
                    <br/><br/>
                    Por favor espera a que el pago se procese antes de realizar nuevos pagos`,
                    showCancelButton: false,
                    allowOutsideClick: false,
                    allowEnterKey: false,
                    allowEscapeKey: false,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#00a9eb",
                    icon: 'warning'
                })
            }
        })


        // if (tempMIP?.Enabled) {
        //     setPaymentType(r.Payments.find(s => s.IdConcept === "MIP"))
        //     setSelectValue("MIP")
        // }

    }

    // useEffect(() => {

    //     if (selectedReference && selectedReference.Payments) {
    //         let tempMIP = selectedReference.Payments.find(p => p.LabelPayment === "Pago Mínimo");
    //         console.log(tempMIP)
    //         if (tempMIP?.Enabled) { handleChangePaymentType("MIP") }


    //     }

    // }, [selectedReference]);

    const handleClose = () => {
        setPaymentType(null)
        setSelectValue("")
        setShow(false);
    }

    const handleBancolombiaModalClose = () => {
        setShowBancolombiaModal(false);
    }

    const handleCBBancolombiaModalClose = () => {
        setShowCBBancolombiaModal(false);
    }

    const handleCMBancolombiaModalClose = () => {
        setShowCMBancolombiaModal(false);
    }

    const handleSVABancolombiaModalClose = () => {
        setShowSVABancolombiaModal(false);
    }

    const handleGUBancolombiaModalClose = () => {
        setShowGUBancolombiaModal(false);
    }

    const handleDRUOModalClose = () => {
        setShowDRUOModal(false);
    }

    const handleOpenDruoModal = () => {



        if (druoData && druoData.status == "VERIFICATION_PENDING") {
            Swal.fire({
                title: "CUENTA PENDIENTE DE VERIFICACIÓN",
                html: "Tu cuenta ha sido creada, una vez sea aprobada por el banco te notificaremos",
                showCancelButton: false,
                allowOutsideClick: false,
                allowEnterKey: false,
                allowEscapeKey: false,
                confirmButtonText: 'Aceptar',
                confirmButtonColor: "#00a9eb",
                icon: 'info'
            })
        }
        else if (druoData && druoData.status == "CONNECTION_FAILED") {
            Swal.fire({
                title: "CUENTA NO SE PUDO CONECTAR",
                html: "Tu cuenta no se pudo conectar. Intentalo de nuevo",
                showCancelButton: false,
                allowOutsideClick: false,
                allowEnterKey: false,
                allowEscapeKey: false,
                confirmButtonText: 'Aceptar',
                confirmButtonColor: "#00a9eb",
                icon: 'warning'
            })
            setShowDRUOModal(true)
        }

        else if (druoData && druoData.pendingPayment) {
            Swal.fire({
                title: "PAGO PENDIENTE",
                html: `Se realizó un débito automático el <b>${druoData.paymentDateCreatedLabel}</b> por valor de <b>${druoData.amount}</b> el cual está siendo procesado por tu banco
                    <br/><br/>
                    Por favor espera a que el pago se procese antes de realizar nuevos pagos`,
                showCancelButton: false,
                allowOutsideClick: false,
                allowEnterKey: false,
                allowEscapeKey: false,
                confirmButtonText: 'Aceptar',
                confirmButtonColor: "#00a9eb",
                icon: 'warning'
            })

        }
        else
            setShowDRUOModal(true)


    }

    const handleEfectyModalClose = () => {
        setShowEfectyModal(false);
    }

    const handlePSEModalClose = () => {
        setShowPSEModal(false);
    }

    const handleNequiModalClose = () => {
        // setQRvalue(null);
        setShowAnotherNumberForm(false);
        setAnotherNumber("")
        // clearInterval(intervalNequi);
        setShowNequiModal(false);
        // setPendingUPPayment(false)
    }

    const handleAdvancePaymentModalClose = () => {
        setApOption(0)
        setShowAdvancePaymentModal(false);
    }

    const handleOverPaymentModalClose = () => {
        setOpOption(0)
        setShowOverPaymentModalModal(false);
    }

    const handlePendingPaymentModalClose = () => {

        setShowPendingPaymentModal(false);
    }

    const handlePlanModalClose = () => {
        setShowPlanModal(false);
    }


    const handleChangeBank = (value) => {
        setBank(value);
    }

    const handleChangeDruoBank = (value) => {
        setDruoBank(value);
    }

    const handleChangeAccountTypeDRUO = (value) => {
        setAccountTypeDRUO(value);
    }

    const handleChangePaymentType = (type) => {
        setSelectValue(type)
        setPaymentType(null)
        setAmountError(false)

        if (type === "MAN") {
            setPaymentType({ LabelAmount: "", IdConcept: "MAN" })
        }
        else if (type) {

            setPaymentType(selectedReference.Payments.find(s => s.IdConcept === type))
        }
        else {
            setPaymentType(null)
        }

    }

    const handleAnotherNumberNequi = () => {
        setShowAnotherNumberForm(true);
    }

    const openNequiModal = () => {
        setShowNequiModal(true)
    }


    const getStatusNequi = () => {

        setLoading(true)
        GetNequiPayment(transactionIdNequi).then(resG => {
            setLoading(false)
            let dataG = JSON.parse(resG.data.data);

            if (resG.status === 200) {

                if (dataG && dataG.status && dataG.status !== "PENDING") {

                    navigate("/paymentResultNequi?transactionId=" + transactionIdNequi)
                }
                else {

                    Swal.fire({
                        title: "El pago se encuentra pendiente",
                        html: "Completa el pago con tu aplicación de Nequi",
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#00a9eb",
                        icon: 'info'
                    })
                }
            }
            else {
                handleNequiModalClose();
                Swal.fire({
                    html: resG.data.message,
                    showCancelButton: true,
                    cancelButtonText: "Contactanos",
                    cancelButtonColor: "#ff9e25",
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#00a9eb",
                    icon: 'error'
                }).then((result) => {
                    if (!result.isConfirmed) {
                        window.open("https://www.meddipay.com.co/contact", "_blank", "noreferrer")
                    }
                });
            }


        });
    }

    const CreateNequiPaymentI = (type) => {

        setLoading(true);
        CreateNequiPayment(anotherNumber, selectedReference.Reference, paymentType.PaymentReferenceNEQUI, paymentType.AmountInCents / 100, type).then(res => {
            setLoading(false);
            if (res.status === 200) {
                setShowAnotherNumberForm(false)
                let data = JSON.parse(res.data.data);
                setTransactionIdNequi(data.transactionId)
                if (type === "QR") {
                    setQRvalue("bancadigital-" + data.transactionId)
                }
                else {
                    setPendingUPPayment(true)
                }

                let inequi = setInterval(() => {


                    GetNequiPayment(data.transactionId).then(resG => {
                        let dataG = JSON.parse(resG.data.data);

                        if (dataG && dataG.status && dataG.status !== "PENDING") {
                            clearInterval(inequi);

                            navigate("/paymentResultNequi?transactionId=" + data.transactionId)
                        }

                    });


                }, 30 * 1000);


                setIntervalNequi(inequi);

            }
            else {

                handleNequiModalClose();
                Swal.fire({
                    html: res.data.message,
                    showCancelButton: true,
                    cancelButtonText: "Contactanos",
                    cancelButtonColor: "#ff9e25",
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#00a9eb",
                    icon: 'error'
                }).then((result) => {
                    if (!result.isConfirmed) {
                        window.open("https://www.meddipay.com.co/contact", "_blank", "noreferrer")
                    }
                });
            }
        })
    }

    const CreateDRUOAccount = () => {

        if (DruoBank === "" || AccountTypeDRUO === "" || accountNumberDRUO === "" || !DruoPolicy) {
            Swal.fire({
                title: "Completa todos los campos",
                // html: "Tu cuenta ha sido creada, una vez sea aprobada por el banco te notificaremos",
                showCancelButton: false,

                confirmButtonText: 'Aceptar',
                confirmButtonColor: "#00a9eb",
                icon: 'warning'
            })

            return;
        }

        setLoading(true);
        CreateDruoAccount(selectedReference.Reference, DruoBank, AccountTypeDRUO, accountNumberDRUO).then(res => {
            setLoading(false);
            if (res.status === 200) {
                handleDRUOModalClose();
                Swal.fire({
                    title: "CUENTA PENDIENTE DE VERIFICACIÓN",
                    html: "Tu cuenta ha sido creada, una vez sea aprobada por el banco te notificaremos",
                    showCancelButton: false,
                    allowOutsideClick: false,
                    allowEnterKey: false,
                    allowEscapeKey: false,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#00a9eb",
                    icon: 'info'
                }).then(res => {
                    if (res.isConfirmed) {
                        window.location.reload(true);
                    }
                })
            } else {


                Swal.fire({
                    html: res.data.message,
                    showCancelButton: true,
                    cancelButtonText: "Contactanos",
                    cancelButtonColor: "#ff9e25",
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#00a9eb",
                    icon: 'error'
                }).then((result) => {
                    if (!result.isConfirmed) {
                        window.open("https://www.meddipay.com.co/contact", "_blank", "noreferrer")
                    }
                });
            }
        })


    }


    const newProcessNequi = () => {
        Swal.fire({
            html: "¿Desea cancelar el proceso actual de pago y generar uno nuevo?",
            showCancelButton: true,
            cancelButtonText: "NO",
            cancelButtonColor: "gray",//"#ff9e25",
            confirmButtonText: 'SI',
            confirmButtonColor: "#00a9eb",
            icon: 'info'
        }).then((result) => {
            if (result.isConfirmed) {
                clearInterval(intervalNequi);
                setLoading(true)
                GetNequiPayment(transactionIdNequi).then(resG => {

                    let dataG = JSON.parse(resG.data.data);

                    if (resG.status === 200) {

                        if (dataG && dataG.status && dataG.status !== "PENDING") {
                            setLoading(false);
                            navigate("/paymentResultNequi?transactionId=" + transactionIdNequi)
                        }
                        else {


                            CancelNequiPayment(anotherNumber ?? null, selectedReference.Reference, paymentType.PaymentReferenceNEQUI, transactionIdNequi).then(resCancel => {

                                setLoading(false);
                                setQRvalue(null);
                                setShowAnotherNumberForm(false);
                                setAnotherNumber("")

                                setShowNequiModal(false);
                                setPendingUPPayment(false);
                                setShow(false);
                                setPaymentType(null);
                                setSelectValue("")
                                searchReference(reference);
                            })


                        }
                    }
                    else {
                        handleNequiModalClose();
                        Swal.fire({
                            html: resG.data.message,
                            showCancelButton: true,
                            cancelButtonText: "Contactanos",
                            cancelButtonColor: "#ff9e25",
                            confirmButtonText: 'Aceptar',
                            confirmButtonColor: "#00a9eb",
                            icon: 'error'
                        }).then((result) => {
                            if (!result.isConfirmed) {
                                window.open("https://www.meddipay.com.co/contact", "_blank", "noreferrer")
                            }
                        });
                    }


                });

            }
        });
    }

    const GetUrlPSE = () => {

        if (!bank || bank === "0") {

            Swal.fire({
                html: "Seleccione el banco",
                showDenyButton: false,
                confirmButtonText: 'ACEPTAR',
                confirmButtonColor: "#00a9eb",
                icon: 'error'
            });

            return;

        }

        setLoading(true);
        GetPSEUrl("SKAL", bank, selectedReference.Reference, paymentType.PaymentReferencePSE, paymentType.AmountInCents / 100, paymentType.LabelPayment).then(res => {

            if (res.status === 200) {
                var data = JSON.parse(res.data.data);
                window.location.replace(data.pseURL);
            }
            else {
                setLoading(false);
                Swal.fire({
                    html: res.data.message,
                    showCancelButton: true,
                    cancelButtonText: "Contactanos",
                    cancelButtonColor: "#ff9e25",
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#00a9eb",
                    icon: 'error'
                }).then((result) => {
                    if (!result.isConfirmed) {
                        window.open("https://www.meddipay.com.co/contact", "_blank", "noreferrer")
                    }
                });

            }

        })
    }


    const validateManualPayment = () => {

        let amount = Number(paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", ""));

        let totalPayment = selectedReference.Payments.find(j => j.IdConcept === "TOP")?.AmountInCents / 100;

        if (!amount) {
            setAmountError(true)
        } else if (amount < 5000) {
            setAmountError(true)
            Swal.fire({
                html: "El valor debe ser mayor a $5.000",
                showDenyButton: false,
                confirmButtonText: 'CONTINUAR',
                confirmButtonColor: "#00a9eb",
                icon: 'warning'
            });
        }
        else if (amount > 2000000000) {
            setAmountError(true)
            Swal.fire({
                html: "El valor debe ser menor a $2.000.000.000",
                showDenyButton: false,
                confirmButtonText: 'CONTINUAR',
                confirmButtonColor: "#00a9eb",
                icon: 'warning'
            });
        }
        else if (amount > totalPayment) {
            setAmountError(true)
            Swal.fire({
                html: "El valor debe ser menor al valor total adeudado",
                showDenyButton: false,
                confirmButtonText: 'CONTINUAR',
                confirmButtonColor: "#00a9eb",
                icon: 'warning'
            });
        }
        else {
            setAmountError(false)

            let minimumPayment = selectedMinimumPayment.AmountInCents / 100;

            if (minimumPayment === 0) {

                setShowAdvancePaymentModal(true);
            }
            else if (amount > minimumPayment) {

                if (selectedReference.IsBilled && !selectedReference.IsInDefault) {
                    setShowPendingPaymentModal(true);
                }
                else {
                    setShowOverPaymentModalModal(true)
                }


            }
            else if (amount < minimumPayment) {

                if (selectedReference.IsBilled && !selectedReference.IsInDefault) {
                    setShowPendingPaymentModal(true);
                }
                else {
                    getCustomPaymentAmount(amount, null);
                }
            }
            else {

                getCustomPaymentAmount(amount, null);
            }
        }
    }

    const handlePendingPayment = (type) => {
        let amount = Number(paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", ""));
        let minimumPayment = selectedMinimumPayment.AmountInCents / 100;

        if (type === 1) {
            setFPayment(1)
            if (amount < minimumPayment) {
                getCustomPaymentAmount(amount, null);

            } else {
                setShowOverPaymentModalModal(true)
            }
        }
        else {


            Swal.fire({
                title: "Vas a entrar en mora...",
                html: `...si no pagas tu cuota de <b>${selectedMinimumPayment?.LabelAmount}</b> antes del <b>${selectedMinimumPayment.LabelLimitDate}</b>. Te recomendamos pagar tu cuota antes de abonar a capital`,
                showDenyButton: true,
                denyButtonText: "Abonar todo a capital",
                denyButtonColor: "grey",
                confirmButtonText: 'Pagar cuota<br/>',

                confirmButtonColor: "#79d853",
                iconColor: "red",
                icon: 'info'
            }).then((result) => {

                if (result.isDenied) {
                    setFPayment(2);
                    setShowOverPaymentModalModal(true)
                }

                if (result.isConfirmed) {
                    setFPayment(1)
                    if (amount < minimumPayment) {
                        getCustomPaymentAmount(amount, null);

                    } else {
                        setShowOverPaymentModalModal(true)
                    }
                }

            });


        }


        setShowPendingPaymentModal(false)
    }

    const getCustomPaymentAmount = (amount, option) => {
        let body = {
            reference: selectedReference.Reference,
            amount: amount,
            paymentOption: option
        };
        setLoading(true)
        CustomPaymentAmount(body).then(res => {
            setLoading(false)

            if (res.status === 200) {

                setShowOverPaymentModalModal(false)
                setShowAdvancePaymentModal(false)

                let data = JSON.parse(res.data.data)


                if (!data.hasError) {

                    if (data.hasWarning) {
                        Swal.fire({
                            html: data.warningMessage,
                            showDenyButton: true,
                            denyButtonText: 'REGRESAR',
                            confirmButtonText: 'CONTINUAR',
                            confirmButtonColor: "#00a9eb",
                            denyButtonColor: "gray",
                            icon: 'warning'
                        }).then((result) => {
                            setShowPendingPaymentModal(false)
                            if (result.isConfirmed) {
                                let temp = data.CustomerReferences[0].Payments[0];
                                setSelectedReference({ ...selectedReference, Payments: [...selectedReference.Payments, temp] })
                                setPaymentType(temp)
                            } else if (result.isDenied) {

                            }
                        })
                    } else {
                        let temp = data.CustomerReferences[0].Payments[0];
                        setSelectedReference({ ...selectedReference, Payments: [...selectedReference.Payments, temp] })
                        setPaymentType(temp)
                    }

                }
                else {
                    Swal.fire({
                        html: data.errorMessage,
                        showDenyButton: false,
                        confirmButtonText: 'CONTINUAR',
                        confirmButtonColor: "#00a9eb",
                        icon: 'error'
                    });
                    setShowOverPaymentModalModal(false)
                    setShowAdvancePaymentModal(false)
                    setShowPendingPaymentModal(false)
                    setPaymentType({ LabelAmount: "", IdConcept: "MAN" })
                }
            }
            else {
                Swal.fire({
                    html: "Se generó un error",
                    showDenyButton: false,
                    confirmButtonText: 'CONTINUAR',
                    confirmButtonColor: "#00a9eb",
                    icon: 'warning'
                });
            }

        })
    }


    const submitWompi = (id) => {

        let pDiv = document.getElementById(id).parentElement.querySelector('.waybox-button');;

        pDiv.click();
    }


    const overPaymentRequest = () => {

        if (opOption === 0) {
            Swal.fire({
                html: "Seleccione una opción",
                showDenyButton: false,
                confirmButtonText: 'CONTINUAR',
                confirmButtonColor: "#00a9eb",
                icon: 'warning'
            });

            return;
        }
        let amount = Number(paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", ""));

        if (FPayment === 0 || FPayment === 1) {
            getCustomPaymentAmount(amount, opOption === 1 ? "NEI" : opOption === 2 ? "RET" : "REI");
        }
        else {
            getCustomPaymentAmount(amount, opOption === 1 ? "NEI" : opOption === 2 ? "NIRET" : "NIREI");
        }


        setOpOption(0)
        setShowPendingPaymentModal(false)
    }


    const advancePaymentRequest = () => {

        if (apOption === 0) {
            Swal.fire({
                html: "Seleccione una opción",
                showDenyButton: false,
                confirmButtonText: 'CONTINUAR',
                confirmButtonColor: "#00a9eb",
                icon: 'warning'
            });

            return;
        }
        let amount = Number(paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", ""));
        getCustomPaymentAmount(amount, apOption === 1 ? "NEI" : apOption === 2 ? "RET" : "REI");
        setApOption(0)
    }

    const handleOpOption = (op) => {
        setOpOption(op);
    }

    const handleApOption = (op) => {
        setApOption(op);
    }

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
        // console.log('totally custom!')
        { },
        );

        return (
            <button
                type="button"
                style={{
                    backgroundColor: "white",
                    width: "100%",
                    border: "solid",
                    borderRadius: "10px",

                    color: "black",
                    display: "inline-flex",
                    fontSize: "14px",
                    borderColor: "gray"
                }}
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }

    function CustomToggleOtherOPS({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
        // console.log('totally custom!')
        { },
        );

        return (
            <button
                type="button"
                style={{
                    backgroundColor: "white",
                    width: "100%",
                    border: "none",

                    color: "black",
                    display: "inline-flex",
                    fontSize: "14px",

                }}
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }


    return (<>



        <Container style={{ marginTop: "20px", marginBottom: "30px" }}>

            <Row style={{ display: referenceInfo ? "none" : "initial" }}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                    <h1 style={{ fontWeight: "bold" }}>Paga tu cuota</h1>
                    <p>
                        A continuación podrás pagar la cuota de tus créditos introduciendo la referencia de pago. La referencia de pago la podrás encontrar en las comunicaciones que te enviamos periódicamente, como correos electronicos, mensajes de texto SMS o whatsapps.
                    </p>
                    <p style={{ fontWeight: "bold" }}>
                        La referencia de pago NO es tu cédula
                    </p>

                    <input type="text" value={reference} onChange={event => { setReference(event.target.value); setReferenceError(false); setErrorMessage(null); }}
                        disabled={cont >= INTENTOS}
                        ref={ReferenciaRef} placeholder="Referencia de pago" style={{ margin: "0 auto" }}
                        className={"gap-2 mb-4 p-2 border-2 rounded-pill mt-4 form-control reference-input " + (referenceError ? "is-invalid" : "")} />

                    {errorMessage && <> <br /> <p style={{ color: "red" }}>{errorMessage}</p> </>}
                    {contMessage && <> <br /> <p style={{ color: "black" }}>{`Has realizado demasiadas consultas, por favor espere ${contTimeout} segundos para realizar una nueva consulta`}</p> </>}

                    <br />
                    <button disabled={loading || cont > INTENTOS} className="mp-big-button bg-mp-green" style={{ border: "none" }} onClick={() => searchReference(reference)}>Consultar</button>
                    <br />
                    <br />
                    <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_URL_CHAN}>¿No encuentras tu referencia de pago?</a>

                </Col>

            </Row>

            <Row style={{ display: referenceInfo ? "initial" : "none" }}>

                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                    <h1 style={{ fontWeight: "bold" }}>Paga tu cuota</h1>
                    <br />
                    {referenceInfo && referenceInfo.CustomerReferences && referenceInfo.CustomerReferences.map(r => {
                        if (r.SearchedReference) {
                            let p = r.Payments.find(p => p.IdConcept === "MIP");
                            return <Card key={r.Reference} className="reference-card" style={{ margin: "0 auto", marginBottom: "20px", borderRadius: "14px", padding: "20px 15px", boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)" }}>


                                <Container>
                                    <Row >
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <span style={{ fontWeight: "bold", fontSize: "20px" }}>Crédito <span style={{ color: "#00a9eb" }}>#{r.Reference}</span></span>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                            Pago Mínimo:
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right", color: p.Textcolor, fontWeight: p.BoldText ? "bold" : "unset" }}>
                                            {p.LabelAmount}
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                            Fecha Límite:
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right", color: p.Textcolor, fontWeight: p.BoldText ? "bold" : "unset" }}>
                                            {p.LabelLimitDate ?? "-"}
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                                            <br />
                                            {p.AmountInCents > 0 ?
                                                // <WompiButton data={{
                                                //     AmountInCents: p.AmountInCents,
                                                //     PaymentReference: p.PaymentReference,
                                                //     EncryptedString: p.EncryptedString,
                                                // }} />
                                                <button className="mp-big-button bg-mp-primary" style={{ border: "none" }} onClick={() => handleOpenModal(r)} >Pagar ahora</button>
                                                : <button className="mp-big-button bg-mp-green" style={{ border: "none" }} onClick={() => handleOpenModal(r)} >Abonar</button>
                                            }

                                        </Col>
                                    </Row>
                                </Container>
                            </Card>
                        }
                        return null
                    })}
                </Col>

                {referenceInfo && referenceInfo.CustomerReferences && referenceInfo.CustomerReferences.length > 1 &&
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", fontSize: "20px", marginBottom: "20px" }}>
                        Tus otros Créditos
                    </Col>}

                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>

                    {referenceInfo && referenceInfo.CustomerReferences && referenceInfo.CustomerReferences.length > 1 && referenceInfo.CustomerReferences.map(r => {
                        if (!r.SearchedReference) {
                            let p = r.Payments.find(p => p.IdConcept === "MIP");
                            return <Card key={r.Reference} className="reference-card" style={{ margin: "0 auto", marginBottom: "20px", borderRadius: "14px", padding: "20px 15px", boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)" }}>


                                <Container>
                                    <Row >
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <span style={{ fontWeight: "bold", fontSize: "20px" }}>Crédito <span style={{ color: "#00a9eb" }}>#{r.Reference}</span></span>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                            Pago Mínimo:
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right", color: p.Textcolor, fontWeight: p.BoldText ? "bold" : "unset" }}>
                                            {p.LabelAmount}
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                            Fecha Límite:
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right", color: p.Textcolor, fontWeight: p.BoldText ? "bold" : "unset" }}>
                                            {p.LabelLimitDate ?? "-"}
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                                            <br />
                                            {p.AmountInCents > 0 ?
                                                // <WompiButton data={{
                                                //     AmountInCents: p.AmountInCents,
                                                //     PaymentReference: p.PaymentReference,
                                                //     EncryptedString: p.EncryptedString,
                                                // }} />
                                                <button className="mp-big-button bg-mp-primary" style={{ border: "none" }} onClick={() => handleOpenModal(r)} >Pagar ahora</button>
                                                : <button className="mp-big-button bg-mp-green" style={{ border: "none" }} onClick={() => handleOpenModal(r)} >Abonar</button>
                                            }

                                        </Col>
                                    </Row>
                                </Container>
                            </Card>
                        }
                        return null
                    })}
                </Col>


                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                    <br />
                    <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={() => {
                        setReferenceInfo(null);
                        setReference("");
                    }}>Regresar</button>

                </Col>

            </Row>

            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>

                    <br />
                    <br />
                    <p>Si necesitas asistencia adicional o tienes alguna consulta, estaremos encantados de atenderte.</p>
                    <button className="mp-big-button bg-mp-orange" style={{ border: "none" }} onClick={goToContact}>¡Contáctanos!</button>
                    <br />
                    <br />
                </Col>
            </Row>
        </Container>


        {loading &&
            (
                <div className="my-loader">
                    <div className="my-loader-container">
                        <img src="assets/images/img/MeddipayLoaderEllipsis.gif" alt="loader"></img>

                    </div>
                </div>
            )}


        <Modal show={show} onHide={handleClose} dialogClassName="modal-payment">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "20px" }}>Crédito <span style={{ color: "#00a9eb" }}>#{selectedReference.Reference}</span>
                    </span>
                </div>
            </Modal.Header>

            <Modal.Body>
                {selectedReference && <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold" }}>
                            Pago mínimo / Fecha límite
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", color: selectedMinimumPayment.Textcolor, fontWeight: selectedMinimumPayment.BoldText ? "bold" : "unset" }}>
                            {selectedMinimumPayment.LabelAmount} / {selectedMinimumPayment.LabelLimitDate ?? "-"}
                            <br />
                            <br />

                        </Col>
                    </Row>

                    {/* <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold" }}>
                            Fecha límite de pago
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", color: selectedMinimumPayment.Textcolor, fontWeight: selectedMinimumPayment.BoldText ? "bold" : "unset" }}>
                            {selectedMinimumPayment.LabelLimitDate ?? "-"}
                            <br />
                            <br />
                        </Col>
                    </Row> */}

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold" }}>
                            <span style={{ fontSize: "14px", color: "#00a9eb", cursor: "pointer" }} onClick={() => setShowPlanModal(true)}><i className="bi bi-table"> </i>Ver plan de pagos completo</span>
                            <br />
                            <br />
                        </Col>

                    </Row>


                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold" }}>
                            ¿Cuánto vas a pagar?

                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", marginTop: "10px" }}>

                            <select className="form-select" style={{ textAlign: "center", border: "solid 5px rgb(188, 190, 192)" }} value={selectValue} onChange={(event) => handleChangePaymentType(event.target.value)}>
                                <option defaultValue={null}>--Selecciona--</option>

                                {selectedReference.Payments?.map(c => {
                                    if (c.IdConcept !== "MAV")
                                        return <option disabled={!c.Enabled} key={c.IdConcept} value={c.IdConcept}>{c.LabelPayment}</option>
                                })}
                                <option value="MAN">Introducir valor manual</option>
                            </select>
                        </Col>

                    </Row>

                    <Row>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                            {paymentType &&
                                <>
                                    <div className="input-group">
                                        <MaskedInput placeholder="Introducir valor" disabled={paymentType.IdConcept !== "MAN"} style={{ marginTop: "20px", textAlign: "center", borderWidth: "2px" }} mask={currencyMask} name="valor-pagar" value={paymentType?.LabelAmount} onChange={(event) => { setAmountError(false); setPaymentType({ ...paymentType, LabelAmount: event.target.value }) }} className={`form-control ${amountError && "is-invalid"}`} autoComplete="off">

                                        </MaskedInput >

                                        {paymentType.PaymentReference && paymentType.IdConcept === "MAV" && <div className="input-group-append" style={{ marginTop: "20px" }}>
                                            <span className="input-group-text" style={{ borderWidth: "2px", borderRadius: "0px", cursor: "pointer" }} onClick={() => { setPaymentType({ LabelAmount: "", IdConcept: "MAN" }) }}><i className="bi bi-x-lg"></i></span>

                                        </div>}
                                    </div>



                                    {/* <input disabled={paymentType.IdConcept !== "MAN"} style={{ width: "100%" }} type="text" value={paymentType.LabelAmount} onChange={(event) => { setPaymentType({ ...paymentType, LabelAmount: event.target.value }) }} className="d-flex justify-content-center gap-2 text-center  p-2 border  border-2 rounded-pill mt-4" /> */}

                                    {/* {paymentType.PaymentReference && paymentType.IdConcept === "MAV" && <button type="submit" style={{ margin: "20px 0px 0px", color: "white", textDecoration: "none", border: "none", minWidth: "200px", borderRadius: "70px", backgroundColor: "gray", height: "54px" }} onClick={() => { setPaymentType({ LabelAmount: "", IdConcept: "MAN" }) }} >Limpiar</button>} */}
                                    {paymentType.PaymentReference && <p style={{ fontSize: "13px", margin: "20px 0px 0px", }}>Este valor se ha calculado a fecha de <b>{dayjs().format("DD/MM/YYYY")}</b> y solamente es valido para pago realizados el mismo día.</p>}

                                    {paymentType.IdConcept === "MAN" && <button type="submit" style={{ margin: "20px 0px 0px", color: "white", textDecoration: "none", border: "none", minWidth: "200px", borderRadius: "70px", backgroundColor: "#00a9eb", height: "54px" }} onClick={validateManualPayment} >Validar</button>}


                                </>

                            }


                        </Col>

                    </Row>


                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "20px" }}>
                            {paymentType && paymentType.PaymentReference && "¿Cómo vas a pagar?"}

                        </Col>

                    </Row>


                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>
                            {paymentType && paymentType.PaymentReference &&
                                <div style={{ cursor: "pointer", height: "70px", padding: "10px", border: "solid 2px", borderColor: "#26daab", width: window.innerWidth > 600 ? "80%" : "100%", margin: "0 auto", borderRadius: "30px" }}
                                    onClick={() => handleOpenDruoModal()}>


                                    {!druoData && <>
                                        <div style={{ float: "left", textAlign: "left", marginLeft: "20px" }}>
                                            <span style={{ fontSize: window.innerWidth < 400 ? "10px" : "15px" }}>Débito automático (DRUO)</span><br />
                                            <span style={{ fontSize: window.innerWidth < 400 ? "8px" : "10px", fontWeight: "500" }}>Conecta tu cuenta en 10s y despreocupate</span>
                                        </div>

                                        <img src="assets/images/img/DRUO.jpg" style={{ width: window.innerWidth < 400 ? "40px" : "50px", cursor: "pointer", float: "right" }} ></img>
                                    </>}

                                    {druoData && druoData.status == "VERIFICATION_PENDING" && <>
                                        <div style={{ float: "left", textAlign: "left", marginLeft: "20px" }}>
                                            <span style={{ fontSize: window.innerWidth < 400 ? "10px" : "15px" }}>Cuenta pendiente</span><br />
                                            <span style={{ fontSize: window.innerWidth < 400 ? "8px" : "10px", fontWeight: "500" }}>Tu cuenta se encuentra pendiente de conexión</span>
                                        </div>

                                        <img src="assets/images/img/pending-icon.png" style={{ width: window.innerWidth < 400 ? "40px" : "50px", cursor: "pointer", float: "right" }} ></img>
                                    </>}

                                    {druoData && druoData.status == "CONNECTION_FAILED" && <>
                                        <div style={{ float: "left", textAlign: "left", marginLeft: "20px" }}>
                                            <span style={{ fontSize: window.innerWidth < 400 ? "10px" : "15px" }}>Conexión de cuenta fallida</span><br />
                                            <span style={{ fontSize: window.innerWidth < 400 ? "8px" : "10px", fontWeight: "500" }}>Tu cuenta no se pudo conectar</span>
                                        </div>

                                        <img src="assets/images/img/close-round-icon.png" style={{ width: window.innerWidth < 400 ? "40px" : "50px", cursor: "pointer", float: "right" }} ></img>
                                    </>}

                                    {druoData && druoData.status == "CONNECTED" && !druoData.pendingPayment && <>
                                        <div style={{ float: "left", textAlign: "left", marginLeft: "20px" }}>
                                            <span style={{ fontSize: window.innerWidth < 400 ? "10px" : "15px" }}>Cuenta conectada</span><br />
                                            <span style={{ fontSize: window.innerWidth < 400 ? "8px" : "10px", fontWeight: "500" }}>Tu cuenta de débito auto. está conectada</span>
                                        </div>

                                        <img src="assets/images/img/bank-account-icon.png" style={{ width: window.innerWidth < 400 ? "40px" : "50px", cursor: "pointer", float: "right" }} ></img>
                                    </>}

                                    {druoData && druoData.pendingPayment && <>
                                        <div style={{ float: "left", textAlign: "left", marginLeft: "20px" }}>
                                            <span style={{ fontSize: window.innerWidth < 400 ? "10px" : "15px" }}>Débito auto. en curso</span><br />
                                            <span style={{ fontSize: window.innerWidth < 400 ? "8px" : "10px", fontWeight: "500" }}>Pendiente respuesta por parte de tu banco</span>
                                        </div>

                                        <img src="assets/images/img/pending.gif" style={{ width: window.innerWidth < 400 ? "40px" : "50px", cursor: "pointer", float: "right" }} ></img>
                                    </>}

                                </div>
                            }
                        </Col>
                    </Row>


                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>
                            {paymentType && paymentType.PaymentReference && <div style={{ cursor: "pointer", height: "70px", padding: "10px", border: "solid 2px", borderColor: "#7c6894", width: window.innerWidth > 600 ? "80%" : "100%", margin: "0 auto", borderRadius: "30px" }}
                                onClick={openNequiModal}>

                                <div style={{ float: "left", textAlign: "left", marginLeft: "20px" }}>
                                    <span style={{ fontSize: "15px" }}>Nequi</span><br />
                                    <span style={{ fontSize: "10px", fontWeight: "500" }}>Pago se aplica al instante</span>
                                </div>

                                <img src="assets/images/img/nequi.png" style={{ width: "50px", cursor: "pointer", float: "right" }} ></img>

                            </div>
                            }
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>
                            {paymentType && paymentType.PaymentReference && <div style={{ cursor: "pointer", height: "70px", padding: "10px", border: "solid 2px", borderColor: "#262c6c", width: window.innerWidth > 600 ? "80%" : "100%", margin: "0 auto", borderRadius: "30px" }}
                                onClick={() => setShowPSEModal(true)}>

                                <div style={{ float: "left", textAlign: "left", marginLeft: "20px" }}>
                                    <span style={{ fontSize: "15px" }}>PSE</span><br />
                                    <span style={{ fontSize: "10px", fontWeight: "500" }}>Pago se aplica al instante</span>
                                </div>

                                <img src="assets/images/img/BotonPSE-01.png" style={{ width: "50px", cursor: "pointer", float: "right" }} ></img>


                            </div>
                            }
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>
                            {paymentType && paymentType.PaymentReference &&
                                <div style={{ cursor: "pointer", height: "70px", padding: "10px", border: "solid 2px", borderColor: "#fcec2f", width: window.innerWidth > 600 ? "80%" : "100%", margin: "0 auto", borderRadius: "30px" }}
                                    onClick={() => setShowEfectyModal(true)}>

                                    <div style={{ float: "left", textAlign: "left", marginLeft: "20px" }}>
                                        <span style={{ fontSize: "15px" }}>Efecty</span><br />
                                        <span style={{ fontSize: "10px", fontWeight: "500" }}>Pago se aplica en 1 día</span>
                                    </div>

                                    <img src="assets/images/img/EfectyImg.png" style={{ width: "50px", cursor: "pointer", float: "right" }} ></img>


                                </div>
                            }


                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>
                            {paymentType && paymentType.PaymentReference &&
                                <div style={{ cursor: "pointer", height: "70px", padding: "10px", border: "solid 2px", borderColor: "#ec251d", width: window.innerWidth > 600 ? "80%" : "100%", margin: "0 auto", borderRadius: "30px" }}
                                    onClick={() => setShowBancolombiaModal(true)}>


                                    <div style={{ float: "left", textAlign: "left", marginLeft: "20px" }}>
                                        <span style={{ fontSize: "15px" }}>Productos Bancolombia</span><br />
                                        <span style={{ fontSize: "10px", fontWeight: "500" }}>Pago se aplica en 1 a 2 días hábiles</span>
                                    </div>

                                    <button className="transfer-button" type="submit" style={{ float: "right" }}></button>

                                </div>
                            }
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>




                            {selectedReference && selectedReference.Payments && selectedReference.Payments.map(p => {
                                if (p.AmountInCents > 0 && p.Enabled) {
                                    return <div key={p.PaymentReference + "_" + p.IdConcept}
                                        style={{ position: "relative", overflow: "hidden", height: "70px", padding: "10px", border: "solid 2px", borderColor: "1", width: window.innerWidth > 600 ? "80%" : "100%", margin: "0 auto", borderRadius: "30px", display: p.IdConcept === paymentType?.IdConcept && p.PaymentReference === paymentType?.PaymentReference ? "" : "none" }}>


                                        <div id={p.PaymentReference + "_" + p.IdConcept} onClick={() => submitWompi(p.PaymentReference + "_" + p.IdConcept)}
                                            style={{ cursor: "pointer", width: "100%", height: "100%", position: "absolute", top: "0", left: "0" }}></div>

                                        <div style={{ float: "left", textAlign: "left", marginLeft: "20px" }}>
                                            <span style={{ fontSize: "15px" }}>Wompi <span style={{ fontSize: "10px" }}>(Daviplata, Tarjeta)</span></span><br />
                                            <span style={{ fontSize: "10px", fontWeight: "500" }}>Pago se aplica al instante</span>
                                        </div>

                                        <WompiButton data={{
                                            AmountInCents: p.AmountInCents,
                                            PaymentReference: p.PaymentReference,
                                            EncryptedString: p.EncryptedString,
                                        }} />

                                    </div>

                                }
                            })}




                        </Col>
                    </Row>



                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "50px auto", width: "80%" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={handleClose}>Regresar</button>
                        </Col>
                    </Row>





                </Container>}
            </Modal.Body>


        </Modal>

        {/* MODAL OPCIONES DE PAGO BANCOLOMBIA */}
        <Modal show={showBancolombiaModal} onHide={handleBancolombiaModalClose} dialogClassName="modal-transfer">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "24px", color: "#000" }}>
                    Puedes pagar en:
                </div>
            </Modal.Header>

            <Modal.Body>
                <Container style={{ textAlign: "center", color: "#000" }}>
                    {/* Primer bloque: Corresponsal Bancario */}
                    <Row
                        style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            border: "2px solid #262c6c",
                            borderRadius: "30px",
                            padding: "15px",
                            margin: "10px 0"
                        }}
                        onClick={() => setShowCBBancolombiaModal(true)}
                    >
                        <Col xs={8} style={{ textAlign: "left" }}>
                            <div style={{ fontWeight: "bold" }}>Corresponsal Bancario</div>
                            <div style={{ fontSize: "14px" }}>Solo Efectivo</div>
                        </Col>
                        <Col xs={4} style={{ textAlign: "right" }}>
                            {/* Icono de Cajeros */}
                            <i className="StoreGPS-button" style={{ fontSize: "24px", color: "#262c6c" }}></i>
                        </Col>
                    </Row>

                    {/* Segundo bloque: Cajeros Multifuncionales */}
                    <Row
                        style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            border: "2px solid #262c6c",
                            borderRadius: "30px",
                            padding: "15px",
                            margin: "10px 0"
                        }}
                        onClick={() => setShowCMBancolombiaModal(true)}
                    >
                        <Col xs={8} style={{ textAlign: "left" }}>
                            <div style={{ fontWeight: "bold" }}>Cajeros Multifuncionales</div>
                            <div style={{ fontSize: "14px" }}>Efectivo o Tarjeta Bancolombia</div>
                        </Col>
                        <Col xs={4} style={{ textAlign: "right" }}>
                            {/* Icono de Cajeros */}
                            <i className="ATM-button" style={{ fontSize: "24px", color: "#262c6c" }}></i>
                        </Col>
                    </Row>

                    {/* Tercer bloque: Sucursal Virtual o APP */}
                    <Row
                        style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            border: "2px solid #262c6c",
                            borderRadius: "30px",
                            padding: "15px",
                            margin: "10px 0"
                        }}
                        onClick={() => setShowSVABancolombiaModal(true)}
                    >
                        <Col xs={8} style={{ textAlign: "left" }}>
                            <div style={{ fontWeight: "bold" }}>Sucursal Virtual o APP</div>
                            <div style={{ fontSize: "14px" }}>Débito a tu cuenta Bancolombia</div>
                        </Col>
                        <Col xs={4} style={{ textAlign: "right" }}>
                            {/* Icono de Sucursal Virtual */}
                            <i className="APP-button" style={{ fontSize: "24px", color: "#262c6c" }}></i>
                        </Col>
                    </Row>

                    {/* Referencia de pago */}
                    <Row>
                        <Col style={{ marginTop: "20px", fontSize: "20px", fontWeight: "bold" }}>
                            Recuerda que tu referencia de pago siempre será
                            <div style={{ color: "#123456", fontSize: "24px", marginTop: "10px" }}>
                                #{selectedReference.Reference}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>

        {/* Modal Corresponsal bancario Bancolombia */}
        <Modal show={showCBBancolombiaModal} onHide={handleCBBancolombiaModalClose} dialogClassName="modal-transfer">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "24px", color: "#000" }}>
                    Corresponsal Bancario
                </div>
            </Modal.Header>

            <Modal.Body>
                <Container style={{ textAlign: "center", color: "#000" }}>
                    {/* Convenio */}
                    <Row>
                        <Col style={{ fontSize: "20px", fontWeight: "bold" }}>Convenio:</Col>
                    </Row>
                    <Row>
                        <Col style={{ fontSize: "28px", color: "#009fe3", fontWeight: "bold" }}>94424</Col>
                    </Row>

                    {/* Referencia */}
                    <Row style={{ marginTop: "10px" }}>
                        <Col style={{ fontSize: "20px", fontWeight: "bold" }}>Referencia:</Col>
                    </Row>
                    <Row>
                        <Col style={{ fontSize: "28px", color: "#009fe3", fontWeight: "bold" }}>{selectedReference.Reference}</Col>
                    </Row>

                    {/* Valor a pagar y Fecha límite */}
                    <Row style={{ marginTop: "20px" }}>
                        <Col style={{ fontSize: "18px" }}>Valor a pagar:</Col>
                    </Row>
                    <Row>
                        <Col style={{ fontSize: "22px", fontWeight: "bold" }}>{paymentType?.LabelAmount}</Col>
                    </Row>
                    <Row>
                        <Col style={{ fontSize: "18px" }}>Fecha limite:</Col>
                    </Row>
                    <Row>
                        <Col style={{ fontSize: "22px", color: selectedMinimumPayment.Textcolor, fontWeight: selectedMinimumPayment.BoldText ? "bold" : "unset" }}>
                            {selectedMinimumPayment.LabelLimitDate ?? "-"}
                        </Col>
                    </Row>

                    {/* Mensaje recordatorio */}
                    <Row style={{ marginTop: "20px", fontSize: "14px", color: "#000" }}>
                        <Col>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <i className="bi bi-exclamation-triangle-fill" style={{ color: "#ffc107", marginRight: "10px", fontSize: "30px" }}></i>
                                RECUERDA! Tu corresponsal Bancario no debe cobrarte por hacer este pago.
                            </div>
                        </Col>
                    </Row>

                    {/* Buscador de sucursales */}
                    <Row style={{ marginTop: "20px" }}>
                        <Col>
                            <a
                                href="https://www.bancolombia.com/puntos-de-atencion/buscar-en-mapa"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#009fe3", textDecoration: "none", fontSize: "16px" }}
                            >
                                <i className="bi bi-pin-map-fill" style={{ marginRight: "5px" }}></i>
                                Buscador de sucursales
                            </a>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>

        {/* Modal Cajero Multifuncional Bancolombia */}
        <Modal show={showCMBancolombiaModal} onHide={handleCMBancolombiaModalClose} dialogClassName="modal-transfer">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "24px", color: "#000" }}>
                    Cajero Multifuncional
                </div>
            </Modal.Header>

            <Modal.Body>
                <Container style={{ textAlign: "center", color: "#000" }}>
                    {/* Convenio */}
                    <Row>
                        <Col style={{ fontSize: "20px", fontWeight: "bold" }}>Convenio:</Col>
                    </Row>
                    <Row>
                        <Col style={{ fontSize: "28px", color: "#009fe3", fontWeight: "bold" }}>94424</Col>
                    </Row>

                    {/* Referencia */}
                    <Row style={{ marginTop: "10px" }}>
                        <Col style={{ fontSize: "20px", fontWeight: "bold" }}>Referencia:</Col>
                    </Row>
                    <Row>
                        <Col style={{ fontSize: "28px", color: "#009fe3", fontWeight: "bold" }}>{selectedReference.Reference}</Col>
                    </Row>

                    {/* Valor a pagar y Fecha límite */}
                    <Row style={{ marginTop: "20px" }}>
                        <Col style={{ fontSize: "18px" }}>Valor a pagar:</Col>
                    </Row>
                    <Row>
                        <Col style={{ fontSize: "22px", fontWeight: "bold" }}>{paymentType?.LabelAmount}</Col>
                    </Row>
                    <Row>
                        <Col style={{ fontSize: "18px" }}>Fecha limite:</Col>
                    </Row>
                    <Row>
                        <Col style={{ fontSize: "22px", color: selectedMinimumPayment.Textcolor, fontWeight: selectedMinimumPayment.BoldText ? "bold" : "unset" }}>
                            {selectedMinimumPayment.LabelLimitDate ?? "-"}
                        </Col>
                    </Row>

                    {/* Mensaje recordatorio */}
                    <Row style={{ marginTop: "20px", fontSize: "14px", color: "#000" }}>
                        <Col>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <i className="bi bi-exclamation-triangle-fill" style={{ color: "#ffc107", marginRight: "10px", fontSize: "30px" }}></i>
                                RECUERDA! Támbien puedes pagar en cajeros normales pero solo con tarjeta Debito de Bancolombia.
                            </div>
                        </Col>
                    </Row>

                    {/* Buscador de sucursales */}
                    <Row style={{ marginTop: "20px" }}>
                        <Col>
                            <a
                                href="https://www.bancolombia.com/puntos-de-atencion/buscar-en-mapa"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#009fe3", textDecoration: "none", fontSize: "16px" }}
                            >
                                <i className="bi bi-pin-map-fill" style={{ marginRight: "5px" }}></i>
                                Buscador de sucursales
                            </a>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>

        {/* Modal Sucursal virtual y app Bancolombia */}
        <Modal show={showSVABancolombiaModal} onHide={handleSVABancolombiaModalClose} dialogClassName="modal-transfer">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "24px", color: "#000" }}>
                    Sucursal Virtual o APP
                </div>
            </Modal.Header>

            <Modal.Body>
                <Container style={{ textAlign: "center", color: "#000" }}>
                    {/* Convenio */}
                    <Row>
                        <Col style={{ fontSize: "20px", fontWeight: "bold" }}>Convenio:</Col>
                    </Row>
                    <Row>
                        <Col style={{ fontSize: "28px", color: "#009fe3", fontWeight: "bold" }}>P.A. MeddiPay - 20865</Col>
                    </Row>

                    {/* Referencia */}
                    <Row style={{ marginTop: "10px" }}>
                        <Col style={{ fontSize: "20px", fontWeight: "bold" }}>Referencia:</Col>
                    </Row>
                    <Row>
                        <Col style={{ fontSize: "28px", color: "#009fe3", fontWeight: "bold" }}>{selectedReference.Reference}</Col>
                    </Row>

                    {/* Valor a pagar y Fecha límite */}
                    <Row style={{ marginTop: "20px" }}>
                        <Col style={{ fontSize: "18px" }}>Valor a pagar:</Col>
                    </Row>
                    <Row>
                        <Col style={{ fontSize: "22px", fontWeight: "bold" }}>{paymentType?.LabelAmount}</Col>
                    </Row>
                    <Row>
                        <Col style={{ fontSize: "18px" }}>Fecha limite:</Col>
                    </Row>
                    <Row>
                        <Col style={{ fontSize: "22px", color: selectedMinimumPayment.Textcolor, fontWeight: selectedMinimumPayment.BoldText ? "bold" : "unset" }}>
                            {selectedMinimumPayment.LabelLimitDate ?? "-"}
                        </Col>
                    </Row>

                    {/* Guía para pagar a través de la APP */}
                    <Row style={{ marginTop: "20px", fontSize: "16px", color: "#009fe3" }}>
                        <Col>
                            <div style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => setShowGUBancolombiaModal(true)}>
                                <i className="bi bi-question-square" style={{ marginRight: "10px", fontSize: "30px" }}></i>
                                Guía para pagar a través de APP Bancolombia Personas
                            </div>

                        </Col>
                    </Row>

                    {/* Mensaje de advertencia */}
                    <Row style={{ marginTop: "20px", fontSize: "14px", color: "#000" }}>
                        <Col>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <i className="bi bi-exclamation-triangle-fill" style={{ color: "#ffc107", marginRight: "10px", fontSize: "30px" }}></i>
                                IMPORTANTE: La fecha de vencimiento en la APP puede diferir de la de pago mensual con MeddiPay; verifica siempre la información actual en nuestro sistema.
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>

        {/* Modal instrucciones de app Bancolombia */}
        <Modal show={showGUBancolombiaModal} onHide={handleGUBancolombiaModalClose} dialogClassName="modal-transfer">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "24px", color: "#000" }}>
                    Guía para pagar por APP
                </div>
            </Modal.Header>

            <Modal.Body>
                <Container style={{ textAlign: "center", color: "#000" }}>
                    {/* Instrucciones de pago */}
                    <Row>
                        <Col style={{ fontSize: "16px", textAlign: "center", lineHeight: "1.5" }}>
                            <p><strong>Para pagar por la APP sigue estos pasos:</strong></p>
                            <p>1. En la sección inferior accede a <strong>"Transacciones"</strong></p>
                            <p>2. Luego ve a <strong>"Pagar y administrar facturas"</strong></p>
                            <p>3. Inicia sesión si se te lo pide.</p>
                            <p>4. Pulsa en <strong>"Inscribir facturas"</strong></p>
                            <p>5. Escribe el número de convenio <strong>20865</strong> o simplemente escribe <strong>MeddiPay</strong></p>
                            <p>6. Pon una descripción corta para acordarte</p>
                            <p>7. Ingresa el número de referencia <strong>{selectedReference.Reference}</strong></p>
                            <p>8. Termina la inscripción pulsando <strong>"inscribir"</strong></p>
                            <p>9. Ahora ve a la inscripción que acabas de realizar y pulsa en <strong>"Pagar y programar"</strong></p>
                            <p>10. Verifica los datos del pago y listo!</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{ fontSize: "20px", textAlign: "center", lineHeight: "1.5" }}>
                            <p><strong>¿Aun tienes dudas?</strong></p>
                        </Col>
                    </Row>

                    {/* Enlace a Video Tutorial y Guía Bancolombia */}
                    <Row style={{ marginTop: "20px" }}>
                        <Col style={{ fontSize: "16px", color: "#009fe3" }}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "15px" }}>
                                <a
                                    href="https://www.youtube.com/watch?v=H3VuDB0dJHk"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#009fe3", textDecoration: "none" }}
                                >
                                    <i className="bi bi-youtube" style={{ fontSize: "24px", marginRight: "5px" }}></i>
                                    Video Tutorial
                                </a>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                        <Col style={{ fontSize: "16px", color: "#009fe3" }}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "15px" }}>
                                <a
                                    href="https://www.bancolombia.com/pagos/facturas"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#009fe3", textDecoration: "none" }}
                                >
                                    <i className="bi bi-question-square" style={{ fontSize: "24px", marginRight: "5px" }}></i>
                                    Guía Bancolombia
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>

        <Modal show={showDRUOModal} onHide={handleDRUOModalClose} dialogClassName="modal-transfer">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "20px" }}>Debito automático
                    </span>
                </div>
            </Modal.Header>

            <Modal.Body>
                {!druoData || druoData.status != "CONNECTED" ? <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", marginTop: "20px", }}>
                            ⚠️ ¡IMPORTANTE! La cuenta bancaria debe pertenecer al tomador del crédito con Meddipay. Por defecto, activaremos el debito automático para todos tus créditos con nosotros.
                            Podrás deshabilitar el debito automático en cualquier momento.
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "20px", }}>
                            Seleccione un banco destacado

                        </Col>

                    </Row>

                    <Row style={{ width: "80%", margin: "0 auto" }}>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center", fontWeight: "bold", }}>
                            <div style={{
                                height: "80px", border: "solid 1px", width: "100%", margin: "0 auto", borderColor: "gray", borderRadius: "5px"
                                , display: "flex", alignItems: "center", justifyContent: "center",
                                marginTop: "20px", cursor: "pointer"
                                , backgroundColor: DruoBank === "ins_e42460a8-c783-4bc3-8dcd-2e6fcab78b77" ? "aliceblue" : "unset"
                            }}
                                onClick={() => { handleChangeDruoBank("ins_e42460a8-c783-4bc3-8dcd-2e6fcab78b77") }}>
                                <img src="assets/images/img/BancolombiaDruo.png" style={{ maxWidth: "130px" }} ></img>
                            </div>


                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center", fontWeight: "bold" }}>
                            <div style={{
                                height: "80px", border: "solid 1px", width: "100%", margin: "0 auto", borderColor: "gray", borderRadius: "5px"
                                , display: "flex", alignItems: "center", justifyContent: "center",
                                marginTop: "20px", cursor: "pointer"
                                , backgroundColor: DruoBank === "ins_9082d56c-4e1d-4967-a828-b7001755e88e" ? "aliceblue" : "unset"
                            }}
                                onClick={() => { handleChangeDruoBank("ins_9082d56c-4e1d-4967-a828-b7001755e88e") }}>
                                <img src="assets/images/img/DaviviendaDruo2.png" style={{ maxWidth: "130px" }}></img>
                            </div>
                        </Col>

                    </Row>

                    <Row style={{ width: "80%", margin: "0 auto" }}>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center", fontWeight: "bold" }}>
                            <div style={{
                                height: "80px", border: "solid 1px", width: "100%", margin: "0 auto", borderColor: "gray", borderRadius: "5px"
                                , display: "flex", alignItems: "center", justifyContent: "center",
                                marginTop: "20px", cursor: "pointer"
                                , backgroundColor: DruoBank === "ins_4fd895cd-c696-4b1a-b406-d4ae89408055" ? "aliceblue" : "unset"
                            }}
                                onClick={() => { handleChangeDruoBank("ins_4fd895cd-c696-4b1a-b406-d4ae89408055") }}>
                                <img src="assets/images/img/BBVADruo.png" style={{ maxWidth: "130px" }} ></img>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center", fontWeight: "bold" }}>
                            <div style={{
                                height: "80px", border: "solid 1px", width: "100%", margin: "0 auto", borderColor: "gray", borderRadius: "5px"
                                , display: "flex", alignItems: "center", justifyContent: "center",
                                marginTop: "20px", cursor: "pointer"
                                , backgroundColor: DruoBank === "ins_12b3d055-9601-4f5c-a5cd-f9feeb5119da" ? "aliceblue" : "unset"
                            }}
                                onClick={() => { handleChangeDruoBank("ins_12b3d055-9601-4f5c-a5cd-f9feeb5119da") }}
                            >
                                <img src="assets/images/img/BogotaDruo.png" style={{ maxWidth: "130px" }} ></img>
                            </div>
                        </Col>

                    </Row>

                    <Row style={{ width: "80%", margin: "0 auto" }}>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center", fontWeight: "bold" }}>
                            <div style={{
                                height: "80px", border: "solid 1px", width: "100%", margin: "0 auto", borderColor: "gray", borderRadius: "5px"
                                , display: "flex", alignItems: "center", justifyContent: "center",
                                marginTop: "20px", cursor: "pointer"
                                , backgroundColor: DruoBank === "ins_4c07fe2a-aa75-425a-9814-f8e5d182fc72" ? "aliceblue" : "unset"
                            }}
                                onClick={() => { handleChangeDruoBank("ins_4c07fe2a-aa75-425a-9814-f8e5d182fc72") }}>
                                <img src="assets/images/img/ItauDruo2.png" style={{ maxWidth: "130px" }} ></img>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center", fontWeight: "bold" }}>
                            <div style={{
                                height: "80px", border: "solid 1px", width: "100%", margin: "0 auto", borderColor: "gray", borderRadius: "5px"
                                , display: "flex", alignItems: "center", justifyContent: "center",
                                marginTop: "20px", cursor: "pointer"
                                , backgroundColor: DruoBank === "ins_19ee97b1-96ea-4f13-8720-869a53e22a37" ? "aliceblue" : "unset"
                            }}
                                onClick={() => { handleChangeDruoBank("ins_19ee97b1-96ea-4f13-8720-869a53e22a37") }}>
                                <img src="assets/images/img/NequiDruo.png" style={{ maxWidth: "130px" }} ></img>
                            </div>
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "30px", }}>
                            O seleccione otro banco

                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", width: "80%" }}>

                            <select className="form-select" style={{ borderWidth: "2px" }} value={DruoBank} onChange={(event) => handleChangeDruoBank(event.target.value)}>
                                <option defaultValue={null}></option>


                                {DruoBanks.map(c => {

                                    return <option key={c.UUID} value={c.UUID}>{c.InstitutionName}</option>
                                })}




                            </select>

                        </Col>
                    </Row>



                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "20px" }}>
                            Seleccione el tipo de cuenta

                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", width: "80%" }}>

                            <select className="form-select" style={{ borderWidth: "2px" }} value={AccountTypeDRUO} onChange={(event) => handleChangeAccountTypeDRUO(event.target.value)}>
                                <option defaultValue={null}></option>



                                <option value={"SAVINGS"}>AHORROS</option>
                                <option value={"CHECKING"}>CORRIENTE</option>
                                <option value={"ELECTRONIC_DEPOSIT"}>DEPOSITO ELECTRONICO</option>

                            </select>

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "20px", }}>
                            Ingrese el numero de cuenta

                        </Col>

                    </Row>
                    <Row>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "0px auto", width: "80%" }}>
                            <input type="text" value={accountNumberDRUO} onChange={event => { setAccountNumberDRUO(event.target.value); setAccountNumberDRUOErrors(false); }}
                                style={{ margin: "0 auto", textAlign: "center" }}
                                className={"gap-2 mb-4 p-2 border-2 rounded-pill mt-4 form-control " + (accountNumberDRUOErrors ? "is-invalid" : "")} />
                        </Col>

                    </Row>

                    <Row>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "justify", margin: "0px auto", width: "80%" }}>
                            <div className="form-check" style={{ margin: "0px auto" }}>
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={DruoPolicy} onChange={() => { setDruoPolicy(!DruoPolicy) }} />
                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                    Meddipay utiliza DRUO para conectar con su cuenta bancaria y procesar transacciones desde las cuentas que conecte. Para procesar estas transacciones declara y garantiza que: (i) nos proporcionará información precisa y completa sobre usted, (ii) nos autoriza a compartir dicha información con DRUO para la prestación de los servicios, (iii) nos autoriza a generar consultas y cobros a las cuentas que conecte a nosotros por medio de DRUO según las obligaciones descritas en este acuerdo. Al aceptar este acuerdo, acepta el tratamiento de sus datos de acuerdo con la <a target="_blank" rel="noreferrer" href="https://druo.com/es-co/legal/politica-de-privacidad-para-usuarios-finales/">Política de Privacidad de DRUO</a> al igual que el <a target="_blank" rel="noreferrer" href="https://druo.com/es-co/legal/acuerdo-de-servicio-para-usuarios-finales/">Acuerdo de Usuarios Finales de DRUO</a>.
                                </label>
                            </div>
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "50px auto", width: "80%" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "rgb(0 169 235)" }} onClick={CreateDRUOAccount}>Inscribir cuenta</button>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "50px auto", width: "80%" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={handleDRUOModalClose}>Regresar</button>
                        </Col>
                    </Row>



                </Container> :
                    <Container>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                                Te giraremos el debito automático a tu cuenta el día <span style={{ color: selectedMinimumPayment.Textcolor, fontWeight: selectedMinimumPayment.BoldText ? "bold" : "unset" }}>{selectedMinimumPayment.LabelLimitDate ?? "-"}</span> por un valor de <span style={{ color: selectedMinimumPayment.Textcolor, fontWeight: selectedMinimumPayment.BoldText ? "bold" : "unset" }}>{selectedMinimumPayment.LabelAmount}</span>
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", marginTop: "20px", }}>
                                Recuerda que debes disponer de saldo en tu cuenta conectada para que podamos cobrarte, asi de facil, nosotros nos encargamos del resto
                            </Col>

                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", marginTop: "20px", }}>
                                <img style={{ width: "100px" }}
                                    src="assets/images/img/blue_check.svg"
                                    alt="payment" />

                            </Col>

                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", marginTop: "20px", }}>
                                Ya tienes registrada una cuenta para debito automático. Para ver mas detalles ve al portal de pacientes o contactanos:
                            </Col>

                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "30px auto", width: "80%" }}>
                                <button className="mp-big-button bg-mp-green" style={{ border: "none" }} onClick={goToChan}>Portal pacientes</button>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto", width: "80%" }}>
                                <button className="mp-big-button bg-mp-orange" style={{ border: "none" }} onClick={goToContact}>¡Contáctanos!</button>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "50px auto", width: "80%" }}>
                                <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={handleDRUOModalClose}>Regresar</button>
                            </Col>
                        </Row>
                    </Container>}
            </Modal.Body>


        </Modal>

        <Modal show={showPSEModal} onHide={handlePSEModalClose} dialogClassName="modal-transfer">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "20px" }}>Pagar mediante PSE
                    </span>
                </div>
            </Modal.Header>

            <Modal.Body>
                <Container>

                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "10px auto" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Crédito <span style={{ color: "#00a9eb" }}>#{selectedReference?.Reference}</span>
                        </span>
                    </div>

                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "50px auto" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Referencia de pago <span style={{ color: "#00a9eb" }}>#{paymentType?.PaymentReferencePSE}</span>
                        </span>
                    </div>

                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "50px auto" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Concepto <span style={{ color: "#00a9eb" }}>{paymentType?.LabelPayment}</span>
                        </span>
                    </div>

                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "50px auto" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Valor <span style={{ color: "#00a9eb" }}>{paymentType?.LabelAmount}</span>
                        </span>
                    </div>




                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "0px", }}>
                            Seleccione un banco destacado

                        </Col>

                    </Row>

                    <Row style={{ width: "80%", margin: "0 auto" }}>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center", fontWeight: "bold", }}>
                            <div style={{
                                height: "80px", border: "solid 1px", width: "100%", margin: "0 auto", borderColor: "gray", borderRadius: "5px"
                                , display: "flex", alignItems: "center", justifyContent: "center",
                                marginTop: "20px", cursor: "pointer"
                                , backgroundColor: bank === "1007" ? "aliceblue" : "unset"
                            }}
                                onClick={() => { handleChangeBank("1007") }}>
                                <img src="assets/images/img/BancolombiaDruo.png" style={{ maxWidth: "130px" }} ></img>
                            </div>


                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center", fontWeight: "bold" }}>
                            <div style={{
                                height: "80px", border: "solid 1px", width: "100%", margin: "0 auto", borderColor: "gray", borderRadius: "5px"
                                , display: "flex", alignItems: "center", justifyContent: "center",
                                marginTop: "20px", cursor: "pointer"
                                , backgroundColor: bank === "1051" ? "aliceblue" : "unset"
                            }}
                                onClick={() => { handleChangeBank("1051") }}>
                                <img src="assets/images/img/DaviviendaDruo2.png" style={{ maxWidth: "130px" }}></img>
                            </div>
                        </Col>

                    </Row>

                    <Row style={{ width: "80%", margin: "0 auto" }}>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center", fontWeight: "bold" }}>
                            <div style={{
                                height: "80px", border: "solid 1px", width: "100%", margin: "0 auto", borderColor: "gray", borderRadius: "5px"
                                , display: "flex", alignItems: "center", justifyContent: "center",
                                marginTop: "20px", cursor: "pointer"
                                , backgroundColor: bank === "1013" ? "aliceblue" : "unset"
                            }}
                                onClick={() => { handleChangeBank("1013") }}>
                                <img src="assets/images/img/BBVADruo.png" style={{ maxWidth: "130px" }} ></img>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center", fontWeight: "bold" }}>
                            <div style={{
                                height: "80px", border: "solid 1px", width: "100%", margin: "0 auto", borderColor: "gray", borderRadius: "5px"
                                , display: "flex", alignItems: "center", justifyContent: "center",
                                marginTop: "20px", cursor: "pointer"
                                , backgroundColor: bank === "1001" ? "aliceblue" : "unset"
                            }}
                                onClick={() => { handleChangeBank("1001") }}
                            >
                                <img src="assets/images/img/BogotaDruo.png" style={{ maxWidth: "130px" }} ></img>
                            </div>
                        </Col>

                    </Row>

                    <Row style={{ width: "80%", margin: "0 auto" }}>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center", fontWeight: "bold" }}>
                            <div style={{
                                height: "80px", border: "solid 1px", width: "100%", margin: "0 auto", borderColor: "gray", borderRadius: "5px"
                                , display: "flex", alignItems: "center", justifyContent: "center",
                                marginTop: "20px", cursor: "pointer"
                                , backgroundColor: bank === "1006" ? "aliceblue" : "unset"
                            }}
                                onClick={() => { handleChangeBank("1006") }}>
                                <img src="assets/images/img/ItauDruo2.png" style={{ maxWidth: "130px" }} ></img>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center", fontWeight: "bold" }}>
                            <div style={{
                                height: "80px", border: "solid 1px", width: "100%", margin: "0 auto", borderColor: "gray", borderRadius: "5px"
                                , display: "flex", alignItems: "center", justifyContent: "center",
                                marginTop: "20px", cursor: "pointer"
                                , backgroundColor: bank === "1507" ? "aliceblue" : "unset"
                            }}
                                onClick={() => { handleChangeBank("1507") }}>
                                <img src="assets/images/img/NequiDruo.png" style={{ maxWidth: "130px" }} ></img>
                            </div>
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "20px", }}>
                            O seleccione otro banco

                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto" }}>

                            <select className="form-select" style={{ textAlign: "center", borderWidth: "2px" }} value={bank} onChange={(event) => handleChangeBank(event.target.value)}>
                                <option defaultValue={null}></option>

                                {bankList.map(c => {

                                    return <option key={c.financialInstitutionCode} value={c.financialInstitutionCode}>{c.financialInstitutionName}</option>
                                })}

                            </select>

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "50px auto", width: "80%" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "rgb(0 169 235)" }} onClick={GetUrlPSE}>Continuar</button>
                        </Col>
                    </Row>


                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "0px auto", width: "80%" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={handlePSEModalClose}>Regresar</button>
                        </Col>
                    </Row>



                </Container>
            </Modal.Body>


        </Modal>

        <Modal show={showNequiModal} onHide={handleNequiModalClose} dialogClassName="modal-transfer">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "20px" }}>Pagar mediante Nequi
                    </span>
                </div>
            </Modal.Header>

            <Modal.Body>
                <Container>

                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "10px auto" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Crédito <span style={{ color: "#00a9eb" }}>#{selectedReference?.Reference}</span>
                        </span>
                    </div>

                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "20px auto" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Referencia de pago <span style={{ color: "#00a9eb" }}>#{paymentType?.PaymentReferenceNEQUI}</span>
                        </span>
                    </div>

                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "20px auto" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Concepto <span style={{ color: "#00a9eb" }}>{paymentType?.LabelPayment}</span>
                        </span>
                    </div>

                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "20px auto" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Valor <span style={{ color: "#00a9eb" }}>{paymentType?.LabelAmount}</span>
                        </span>
                    </div>





                    {!QRvalue && !showAnotherNumberForm && !pendingUPPayment && <>

                        <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "50px 0px 20px 0px" }}>
                            <span style={{ fontWeight: "bold", fontSize: "20px" }}>¿Qué NEQUI vas a usar para el pago?
                            </span>
                        </div>



                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto", width: "85%" }}>
                                <button className="mp-big-button" style={{ width: "100%", border: "none", backgroundColor: "rgb(0 169 235)", height: "60px" }} onClick={() => CreateNequiPaymentI("UP")}>Recibir notificación en {selectedReference.MaskedPhone}</button>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto", width: "85%" }}>
                                <button className="mp-big-button" style={{ width: "100%", border: "none", backgroundColor: "rgb(0 169 235)", height: "60px" }} onClick={handleAnotherNumberNequi}>Recibir notificación en otro celular</button>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto", width: "85%" }}>
                                <button className="mp-big-button" style={{ width: "100%", border: "none", backgroundColor: "rgb(0 169 235)", height: "60px" }} onClick={() => CreateNequiPaymentI("QR")}>Generar código QR</button>
                            </Col>
                        </Row>


                    </>
                    }
                    {
                        pendingUPPayment && <>
                            <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "30px 0px 20px 0px" }}>
                                <span style={{ fontWeight: "bold", fontSize: "17px" }}>La notificación ha sido enviada a su celular, y luego de autenticarse en su App de Nequi, deberá aceptar el pago desde el centro de notificaciones.
                                </span>
                            </div>
                            <Row style={{ textAlign: "center" }}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Spinner animation="border" role="status" style={{ color: "#00a9eb" }} >
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner></Col>
                            </Row>
                            <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "20px auto" }}>
                                <span style={{ fontWeight: "bold", fontSize: "17px" }}>Oprime el boton de continuar cuando hayas realizado el pago
                                </span>
                            </div>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "0px auto", width: "80%" }}>
                                    <button className="mp-big-button" style={{ border: "none", backgroundColor: "rgb(0 169 235)" }} onClick={getStatusNequi}>Continuar</button>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto", width: "80%" }}>
                                    <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={newProcessNequi}>Generar nuevo proceso</button>
                                </Col>
                            </Row>
                        </>
                    }
                    {showAnotherNumberForm &&
                        <>
                            <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "30px 0px 0px 0px" }}>
                                <span style={{ fontWeight: "bold", fontSize: "17px" }}>Ingresa el número de celular
                                </span>
                            </div>
                            <Row>

                                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "0px auto", width: "80%" }}>
                                    <input type="text" value={anotherNumber} onChange={event => { setAnotherNumber(event.target.value); setAnotherNumberError(false); }}
                                        style={{ margin: "0 auto" }}
                                        className={"gap-2 mb-4 p-2 border-2 rounded-pill mt-4 form-control reference-input " + (anotherNumberError ? "is-invalid" : "")} />
                                </Col>

                            </Row>

                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto", width: "80%" }}>
                                    <button className="mp-big-button" style={{ border: "none", backgroundColor: "rgb(0 169 235)" }} onClick={() => {
                                        if (typeof Number(anotherNumber) !== 'number') { setAnotherNumberError(true); return };
                                        if (anotherNumber.length < 10) { setAnotherNumberError(true); return };
                                        CreateNequiPaymentI("UP");
                                    }}>Enviar</button>
                                </Col>
                            </Row>
                        </>



                    }

                    {QRvalue && <>
                        <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "20px auto" }}>
                            <span style={{ fontWeight: "bold", fontSize: "17px" }}>Escanea este código QR mediante tu aplicación de NEQUI para realizar el pago
                            </span>
                        </div>

                        <Row>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "0px auto", width: "80%" }}>
                                <QRCode value={QRvalue} />
                            </Col>

                        </Row>

                        <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "20px auto" }}>
                            <span style={{ fontWeight: "bold", fontSize: "17px" }}>Y oprime el boton de continuar cuando hayas realizado el pago
                            </span>
                        </div>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "0px auto", width: "80%" }}>
                                <button className="mp-big-button" style={{ border: "none", backgroundColor: "rgb(0 169 235)" }} onClick={getStatusNequi}>Continuar</button>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto", width: "80%" }}>
                                <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={newProcessNequi}>Generar nuevo proceso</button>
                            </Col>
                        </Row>


                    </>}

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "30px auto", width: "80%" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={handleNequiModalClose}>Regresar</button>
                        </Col>
                    </Row>



                </Container>
            </Modal.Body>


        </Modal>



        <Modal show={showEfectyModal} onHide={handleEfectyModalClose} dialogClassName="modal-transfer">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "20px" }}>Pagar mediante Efecty
                    </span>
                </div>
            </Modal.Header>

            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid", borderColor: "black", width: window.innerWidth > 600 ? "60%" : "80%" }}>
                            <br />
                            <b>Convenio:</b>{window.innerWidth < 600 && <br />} 113754<br /><br />
                            <b>Nombre convenio:</b>{window.innerWidth < 600 && <br />} Meddipay pago facturas<br /><br />
                            <b>Referencia:</b> {window.innerWidth < 600 && <br />} {selectedReference?.Reference}<br /><br />


                        </Col>
                    </Row>

                    {/* <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", width: "80%" }}>
                            <p>Una vez realizada la transferencia, por favor, enviar soporte, junto con referencia del crédito a cual esta realizando el pago al siguiente correo electrónico: </p>
                            <a target="_blank" rel="noreferrer" href="mailto:hola@meddipay.com">hola@meddipay.com</a>

                        </Col>
                    </Row> */}


                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "50px auto", width: "80%" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={handleEfectyModalClose}>Regresar</button>
                        </Col>
                    </Row>



                </Container>
            </Modal.Body>


        </Modal>


        <Modal show={showAdvancePaymentModal} onHide={handleAdvancePaymentModalClose} dialogClassName="modal-transfer">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "20px" }}>¿Que desea hacer con el abono anticipado?
                    </span>
                </div>
            </Modal.Header>

            <Modal.Body>
                <Container>
                    <Row>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", width: "80%" }}>
                            Usted a ingresado el valor a pagar de  <b>{paymentType?.LabelAmount}</b> pero ahora necesitamos saber que debemos hacer con este abono anticipado.
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid 1px", borderColor: "black", width: "80%", borderRadius: "10px", cursor: "pointer", backgroundColor: apOption === 1 ? "#eeffe7" : "unset" }} onClick={() => handleApOption(1)}>
                            <p style={{ fontWeight: "bold", marginTop: "15px" }}>Reservar para futuras cuotas.</p>
                            <p> El valor excedente se guardara en nuestro sistema y se aplicara a la siguiente cuota tan pronto se emita su facturación.
                                Nada cambiara en tu plan de pagos.</p>

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid 1px", borderColor: "black", width: "80%", borderRadius: "10px", cursor: "pointer", backgroundColor: apOption === 2 ? "#eeffe7" : "unset" }} onClick={() => handleApOption(2)}>
                            <p style={{ fontWeight: "bold", marginTop: "15px" }}>Abonar para reducir el plazo del Crédito.</p>
                            <p> El plazo de la finalización de tu crédito disminuirá siempre y cuando sea posible, y se conservará el mismo valor de cuota. Recuerde que esta opción no afecta a su Proxima facturación que será el dia <b>{selectedReference.NextBillingDate}</b>.</p>

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid 1px", borderColor: "black", width: "80%", borderRadius: "10px", cursor: "pointer", backgroundColor: apOption === 3 ? "#eeffe7" : "unset" }} onClick={() => handleApOption(3)}>
                            <p style={{ fontWeight: "bold", marginTop: "15px" }}>Abonar para reducir la cuota del Crédito</p>
                            <p>El valor de la cuota disminuirá, pero el plazo de tu plan de pagos seguirá siendo el mismo.  Recuerde que esta opción no afecta a su Proxima facturación que será el dia <b>{selectedReference.NextBillingDate}</b>.</p>

                        </Col>
                    </Row>



                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ textAlign: "center", margin: "20px auto" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={handleAdvancePaymentModalClose}>Regresar</button>

                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ textAlign: "center", margin: "20px auto" }}>

                            <button className="mp-big-button bg-mp-primary" style={{ border: "none" }} onClick={advancePaymentRequest} >Continuar</button>
                        </Col>
                    </Row>



                </Container>
            </Modal.Body>


        </Modal>


        <Modal show={showOverPaymentModal} onHide={handleOverPaymentModalClose} dialogClassName="modal-transfer">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "20px" }}>¿Que deseas hacer con el abono anticipado?
                    </span>
                </div>
            </Modal.Header>

            <Modal.Body>
                <Container>
                    <Row>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", width: "80%" }}>
                            Por favor selecciona que deseas realizar con el pago anticipado de {FPayment !== 2 ? <b>$ {moneyLocale.format((paymentType?.LabelAmount ? paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", "") : 0) - (selectedMinimumPayment?.AmountInCents) / 100)}</b>
                                :
                                <b>$ {moneyLocale.format((paymentType?.LabelAmount ? paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", "") : 0))}</b>}
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid 1px", borderColor: "black", width: "80%", borderRadius: "10px", cursor: "pointer", backgroundColor: opOption === 1 ? "#eeffe7" : "unset" }} onClick={() => handleOpOption(1)}>
                            <p style={{ fontWeight: "bold", marginTop: "15px" }}>Reservar para futuras cuotas.</p>
                            <p>  El valor del pago anticipado se guardara en nuestro sistema y se aplicara a la siguiente cuota tan pronto se emita su facturación. Nada cambiara en tu plan de pagos.
                                Afectara primero a tu facturación mas cercana que es el dia <b>{selectedReference?.NextBillingDate}</b></p>

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid 1px", borderColor: "black", width: "80%", borderRadius: "10px", cursor: "pointer", backgroundColor: opOption === 2 ? "#eeffe7" : "unset" }} onClick={() => handleOpOption(2)}>
                            <p style={{ fontWeight: "bold", marginTop: "15px" }}>Abonar para reducir el plazo del Crédito.</p>
                            <p> El plazo de finalización de tu crédito disminuirá siempre y cuando sea posible, y se conservará el mismo valor de cuota. Recuerda que esto no afecta a la facturación del dia <b>{selectedReference?.NextBillingDate}</b></p>

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid 1px", borderColor: "black", width: "80%", borderRadius: "10px", cursor: "pointer", backgroundColor: opOption === 3 ? "#eeffe7" : "unset" }} onClick={() => handleOpOption(3)}>
                            <p style={{ fontWeight: "bold", marginTop: "15px" }}>Abonar para reducir la cuota del Crédito</p>
                            <p>El valor de la cuota disminuirá, pero el plazo de tu plan de pagos seguirá siendo el mismo. Recuerda que esto no afecta a la facturación del dia <b>{selectedReference?.NextBillingDate}</b></p>

                        </Col>
                    </Row>



                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ textAlign: "center", margin: "20px auto" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={handleOverPaymentModalClose}>Regresar</button>

                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ textAlign: "center", margin: "20px auto" }}>

                            <button className="mp-big-button bg-mp-primary" style={{ border: "none" }} onClick={overPaymentRequest}>Continuar</button>
                        </Col>
                    </Row>



                </Container>
            </Modal.Body>


        </Modal>

        <Modal show={showPendingPaymentModal} onHide={handlePendingPaymentModalClose} dialogClassName="modal-transfer">


            <Modal.Body>
                <Container style={{ minHeight: window.innerWidth < 600 ? "100vh" : "unset" }}>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ fontSize: "20px", textAlign: "center", margin: "20px auto", width: window.innerWidth < 600 ? "100%" : "80%", fontWeight: "bold" }}>

                            <img className="img-fluid" style={{ width: "100px" }}
                                src="assets/images/img/PPAlert.svg"
                                alt="paymentA" />
                            <br />
                            <br />
                            Cuentas con una cuota proxima a vencer
                        </Col>
                    </Row>



                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", width: window.innerWidth < 600 ? "100%" : "80%" }} >
                            <p style={{ marginTop: "15px", fontSize: "15px" }}>

                                Actualmente cuentas con una cuota proxima a vencer y por lo tanto pendiente por pagar por un valor de <b>{selectedMinimumPayment?.LabelAmount}</b> y fecha limite de pago el <b>{selectedMinimumPayment.LabelLimitDate}</b>.
                                Te recomendamos usar el valor introducido manualmente de <b>{paymentType?.LabelAmount}</b> para cubrir esta cuota.
                                <br /><br />
                                {Number(paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", "")) > (selectedMinimumPayment?.AmountInCents / 100) && <span>El sobrante de los <b>$ {moneyLocale.format((paymentType?.LabelAmount ? paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", "") : 0) - (selectedMinimumPayment?.AmountInCents) / 100)}</b> podrás usarlo para abonar a capital.</span>}
                                {Number(paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", "")) < (selectedMinimumPayment?.AmountInCents / 100) && <span>Recuerda que después de este pago, aun deberas pagar <b>$ {moneyLocale.format(((paymentType?.LabelAmount ? paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", "") : 0) - (selectedMinimumPayment?.AmountInCents) / 100) * -1)}</b> antes de la fecha limite de pago para seguir disponiendo de tu cupo y no incurrir en intereses moratorios.</span>}

                            </p>

                        </Col>
                    </Row>



                    <Row>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto" }}>

                            <button className="mp-big-button bg-mp-green" style={{ border: "none" }} onClick={() => handlePendingPayment(1)}>
                                Pagar cuota
                            </button>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={handlePendingPaymentModalClose}>Regresar</button>

                        </Col>
                    </Row>




                </Container>

                <Container>

                    <Row>


                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto" }}  >

                            <Accordion flush style={{ margin: "0 auto", textAlign: "center" }} className="accordion-movements">


                                <Card key="0" style={{ border: "none", boxShadow: "none", marginBottom: "10px", maxWidth: "100%", width: "100%" }}>
                                    <Card.Header style={{ padding: "0", border: "none" }}>
                                        <CustomToggleOtherOPS eventKey="0">
                                            <div style={{ padding: "15px", fontWeight: "bold", textAlign: "center", width: "100%", color: "#00a9eb", textDecoration: "underline" }}>Otras opciones</div>

                                        </CustomToggleOtherOPS>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body style={{ border: "none", padding: "5px", paddingTop: "20px", paddingBottom: "10px" }}>
                                            <Container>


                                                <button className="mp-big-button" style={{ borderRadius: "15px", border: "solid 2px red", backgroundColor: "white", color: "black" }} onClick={() => handlePendingPayment(2)}>Abonar todo el valor ({paymentType?.LabelAmount}) a capital</button>

                                            </Container>

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                            </Accordion>



                        </Col>
                    </Row>
                </Container>

            </Modal.Body>


        </Modal>

        <Modal show={showPlanModal} onHide={handlePlanModalClose} dialogClassName="modal-transfer">
            <Modal.Header closeButton>
                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "20px" }}>Plan de pagos Crédito<br /><span style={{ color: "#00a9eb" }}>#{selectedReference.Reference}</span>
                    </span>
                </div>
            </Modal.Header>

            <Modal.Body>
                {selectedReference && <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold" }}>
                            Pago mínimo
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", color: selectedMinimumPayment.Textcolor, fontWeight: selectedMinimumPayment.BoldText ? "bold" : "unset" }}>
                            {selectedMinimumPayment.LabelAmount}
                            <br />
                            <br />

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold" }}>
                            Fecha límite de pago
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", color: selectedMinimumPayment.Textcolor, fontWeight: selectedMinimumPayment.BoldText ? "bold" : "unset" }}>
                            {selectedMinimumPayment.LabelLimitDate ?? "-"}
                            <br />
                            <br />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>

                            <Accordion flush style={{ margin: "0 auto" }} className="accordion-movements" >
                                {
                                    selectedReference && selectedReference.Installments && selectedReference.Installments.map(i => {

                                        return <Card key={i.NumInstalment} style={{ border: "none", boxShadow: "none", marginBottom: "10px", maxWidth: "100%", width: "100%" }}>
                                            <Card.Header style={{ padding: "0", border: "none" }}>
                                                <CustomToggle eventKey={i.NumInstalment}>
                                                    <div style={{ marginLeft: "10px", padding: "15px", fontWeight: "bold" }}>{i.LabelInstalment}</div>
                                                    <div style={{ position: "absolute", "right": "15px", padding: "10px" }}>
                                                        <div style={{ padding: "5px", color: i.ColorTextInstalmentStatus, backgroundColor: i.ColorFillInstalmentStatus, borderRadius: "5px", fontWeight: "500" }}>
                                                            {i.LabelInstalmentStatus}
                                                        </div>

                                                    </div>
                                                </CustomToggle>
                                            </Card.Header>

                                            <Accordion.Collapse eventKey={i.NumInstalment}>
                                                <Card.Body style={{ border: "none", padding: "5px", paddingTop: "20px", paddingBottom: "10px" }}>
                                                    <Container>
                                                        <Row>
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", fontWeight: "bold", marginBottom: "0px", paddingRight: "0px" }}>Valor Cuota</Col>
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", textAlign: "right", marginBottom: "0px", paddingLeft: "0px", fontWeight: "500" }}>{i.LabelTotalInstalment}<span style={{ color: "#03A9F4" }}>{i.LabelPaidInstalment}</span></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={7} sm={7} md={7} lg={7} xl={7} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Fecha de Facturación</Col>
                                                            <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px", fontWeight: "500" }}>{i.LabelBillingDate}</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={7} sm={7} md={7} lg={7} xl={7} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Fecha Limite de Pago</Col>
                                                            <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px", color: i.ColorText, fontWeight: "500" }}>{i.LabelLimitDate}</Col>
                                                        </Row>


                                                        <Row>
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Pago Mínimo</Col>
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px", color: i.ColorText, fontWeight: "500" }}>{i.LabelMinimumPayment}</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Interses moratorios</Col>
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px", color: i.ColorText, fontWeight: "500" }}>{i.LabelPenalty}</Col>
                                                        </Row>
                                                    </Container>

                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    })

                                }
                            </Accordion>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "50px auto", width: "80%" }}>
                            <button className="mp-big-button" style={{ border: "none", backgroundColor: "gray" }} onClick={handlePlanModalClose}>Regresar</button>
                        </Col>
                    </Row>


                </Container>}
            </Modal.Body>


        </Modal>


    </>)
}


export default Payment;